<template>
  <div id="hbh" v-cloak>
    <div class="table-responsive">
      <table class="tableMob">
        <thead>
          <tr
            class="title"
            :class="{ developGreen: develop === config.VUE_APP_ID_ADT }"
          >
            <th class="CP"></th>
            <th class="CN"></th>
            <th class="CF"></th>
            <template v-if="title.includes('Scoreboard')">
              <th class="CP"></th>
            </template>
            <template v-if="data.score_type === 'P' || data.tt_type === 'P' && data.stableford === 'N'">
              <th class="CP"></th>
            </template>
            <th class="CP">
              1
            </th>
            <th class="CP">
              2
            </th>
            <th class="CP">
              3
            </th>
            <th class="CP">
              4
            </th>
            <th class="CP">
              5
            </th>
            <th class="CP">
              6
            </th>
            <th class="CP">
              7
            </th>
            <th class="CP">
              8
            </th>
            <th class="CP">
              9
            </th>
            <th class="CP">
              Out
            </th>
            <th class="CP">
              10
            </th>
            <th class="CP">
              11
            </th>
            <th class="CP">
              12
            </th>
            <th class="CP">
              13
            </th>
            <th class="CP">
              14
            </th>
            <th class="CP">
              15
            </th>
            <th class="CP">
              16
            </th>
            <th class="CP">
              17
            </th>
            <th class="CP">
              18
            </th>
            <th class="CP">
              IN
            </th>
            <th class="CP">
              TOT
            </th>
          </tr>
          <template v-if="data.multi_course === 'Y'">
            <tr
              :class="multi.course_X_colour + 'row'"
              v-for="(multi, index) in data.courses.courses_entry"
              :key="index"
            >
              <th class="CP">
                {{ multi.course_X_id }}
              </th>
              <th class="CN" style="text-align:left">
                <span v-if="index === 1">
                  Player Name
                </span>
              </th>
              <th class="CF">
                <span v-if="index === 1">
                  Nat.
                </span>
              </th>
              <th class="CP">
                <span v-if="index === 1">
                  Tot
                </span>
              </th>
              <template v-if="title.includes('Scoreboard')">
                <th class="CP">
                  <span v-if="index === 1">
                    VsPar
                  </span>
                </th>
              </template>
              <th
                class="CP"
                v-for="(course, index) in multi.course_X_par
                  .split(',')
                  .slice(0, 9)"
                :key="index + Math.random()"
              >
                {{ course }}
              </th>
              <th class="CP">
                {{ multi.course_X_out_par.slice(1) }}
              </th>
              <th
                class="CP"
                v-for="(course, index) in multi.course_X_par
                  .split(',')
                  .slice(9, 18)"
                :key="index + Math.random()"
              >
                {{ course }}
              </th>
              <th class="CP">
                {{ multi.course_X_in_par.slice(1) }}
                <!-- {{ multi.course_X_in_par }} -->
              </th>
              <th class="CP">
                <!-- {{ multi.course_X_total_par.slice(1) }} -->
                {{ multi.course_X_total_par }}
              </th>
            </tr>
          </template>
          <tr
            v-else
            class="title"
            :class="{ developGreen: develop === config.VUE_APP_ID_ADT }"
          >
            <th class="CP">
              Pos
            </th>
            <th class="CN" style="text-align:left">
              Player Name
            </th>
            <th class="CF">
              Nat.
            </th>
            <template v-if="data.score_type === 'P' || data.tt_type === 'P' && data.stableford === 'N'">
            <th class="CT">
              Tot
            </th>
            <template v-if="title.includes('Scoreboard')">
              <th>
                VsPar
              </th>
            </template>
            </template>
            <template v-if="data.score_type === 'P' || data.tt_type === 'P' && data.stableford === 'Y'">
              <th>
                Points
              </th>
            </template>
            <th
              class="CS"
              v-for="(course, index) in data.course_par.split(',').slice(0, 9)"
              :key="index + Math.random()"
            >
              {{ course }}
            </th>
            <th class="CS">
              {{ data.course_out_par.slice(1) }}
            </th>
            <th
              class="CS"
              v-for="(course, index) in data.course_par.split(',').slice(9, 18)"
              :key="index + Math.random()"
            >
              {{ course }}
            </th>
            <th class="CS">
              {{ data.course_in_par.slice(1) }}
            </th>
            <th class="CS">
              {{ data.course_total_par }}
            </th>
          </tr>
        </thead>
        <template v-if="
          data.score_type === 'P' ||
          (data.tt_type === 'P' && data.stableford === 'N')
        ">
          <tbody>
            <tr
              class="RT"
              v-for="(playerData, index) in data.scores.scores_entry"
              :key="index + Math.random()"
            >
              <td v-if="playerData.pos !== 'CUT'">
                <span
                  v-if="
                    index !== 0 &&
                      (playerData.tied !== '=' ||
                        scores[index - 1].pos !== playerData.pos)
                  "
                  class="CP pos"
                >
                  {{ playerData.pos }}
                </span>
                <span v-else-if="index === 0" class="CP pos">
                  {{ playerData.pos }}
                </span>
                <span v-else></span>
              </td>

              <td
                style="text-align:left"
                v-if="playerData.pos !== 'CUT'"
                :title="playerData.member_no"
                :class="{
                  'text-up': playerData.posChang == 'up',
                  'text-down': playerData.posChang == 'down',
                }"
              >

                <template v-if="playerData.profile == 'Y'">
                  <a
                    class="linkcolor"
                    :href="
                      '/playerprofile/' + playerData.id
                    "
                  >
                    {{ playerData.initials }} {{ playerData.surname }}</a
                  > 
                </template>
                <template v-else>
                    {{ playerData.initials }} {{ playerData.surname }}
                </template>
                <span style="margin-left: 5px;" v-if="playerData.pro_ind === 'Am'">(a)</span>
                <span v-if="data.multi_course === 'Y'">
                  <span :class="playerData['course_colour_R' + data.pft_round]">
                    •
                  </span>
                </span>
                <span
                  v-if="showGender === 'Y'"
                  class="multiDot-size-reportgender"
                  :class="{female : playerData.gender === 'F', male : playerData.gender === 'M'}"
                  >•</span>
              </td>
              <td
                v-if="playerData.pos !== 'CUT'"
                class="CF"
                :title="playerData.nationality"
              >
                <span>
                  <template v-if="represents_flag_type === 'N'">
                    {{playerData.nationality}}
                  </template>
                  <template v-else>
                    <img
                      class="flag"
                      :src="
                        (config.VUE_APP_FLAG_URL + playerData.nationality)
                          | lowercase
                      "
                    />
                  </template>
                </span>
              </td>
              <template v-if="data.score_type === 'P' || data.tt_type === 'P' && data.stableford === 'N'">
                <td v-if="playerData.pos !== 'CUT'" class="CT">
                  <span v-if="playerData.vspar < 0" :class="up">
                    {{ playerData.vspar }}
                  </span>
                  <span v-else-if="playerData.vspar > 0" :class="down">
                    {{ playerData.vspar }}
                  </span>
                  <span v-else-if="playerData.vspar == 0">
                    {{ playerData.vspar }}
                  </span>
                  <span v-else>
                    <!-- {{ playerData.score }} -->
                    {{ playerData.vspar }}
                  </span>
                </td>
                <template v-if="playerData['score_R' + roundsPlayer] == 'WDN' || playerData['score_R' + roundsPlayer] == 'RTD' || playerData['score_R' + roundsPlayer] == 'DSQ'">
                  <td></td>
                </template>
                <template v-else>
                  <template v-if="title.includes('Scoreboard')">
                    <td v-if="playerData.pos !== 'CUT'">
                      <span
                        v-if="playerData['vspar_R' + data.pft_round] < 0"
                        :class="up"
                      >
                        {{ playerData["vspar_R" + data.pft_round] }}
                      </span>
                      <span
                        v-else-if="playerData['vspar_R' + data.pft_round] > 0"
                        :class="down"
                      >
                        {{ playerData["vspar_R" + data.pft_round] }}
                      </span>
                      <span v-else>
                        {{ playerData["vspar_R" + data.pft_round] }} 
                      </span>
                    </td>
                  </template>
                </template>
              </template>
              <template v-if="data.score_type === 'P' || data.tt_type === 'P' && data.stableford === 'Y'">
                <td>{{playerData.score}}</td>
              </template>
              <td class="cut" colspan="30" v-if="playerData.pos === 'CUT'">
                {{ playerData.cut_round }}
              </td>
              <template v-if="playerData.vspar !== 'RTD' && playerData.vspar !== 'WDN'">
                <template v-if="playerData.pos !== 'CUT'">
                  <td
                    class="CS"
                    v-for="(r1, index) in (
                      playerData['hole_scores_R' + roundsPlayer] || ''
                    )
                      .split(',')
                      .slice(0, 9)"
                    :key="index + Math.random()"
                    :class="{'twoUnder': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',')[index] == -2, 
                    'oneUnder': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',')[index] == -1,
                    'threeUnder': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',')[index] == -3,
                    'twoOver': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',')[index] > 1,
                    'oneOver': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',')[index] == 1,
                    } 
                    "
                  >
                    <span
                      class="twoUnder"
                      v-if="
                        (playerData['hole_vspars_R' + roundsPlayer] || '').split(
                          ','
                        )[index] == -2
                      "
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneUnder"
                      v-else-if="
                        (playerData['hole_vspars_R' + roundsPlayer] || '').split(
                          ','
                        )[index] == -1
                      "
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="threeUnder"
                      v-else-if="
                        (playerData['hole_vspars_R' + roundsPlayer] || '').split(
                          ','
                        )[index] == -3
                      "
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneOver"
                      v-else-if="
                        (playerData['hole_vspars_R' + roundsPlayer] || '').split(
                          ','
                        )[index] == 1
                      "
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="twoOver"
                      v-else-if="
                        (playerData['hole_vspars_R' + roundsPlayer] || '').split(
                          ','
                        )[index] > 1
                      "
                    >
                      {{ r1 }}
                    </span>
                    <span v-else>
                      {{ r1 }}
                    </span>
                  </td>
                  <td class="CS">
                    <span
                      v-if="
                        sum(
                          (playerData['hole_scores_R' + roundsPlayer] || '')
                            .split(',')
                            .slice(0, 9)
                        ) > data.course_out_par
                      "
                      class="down"
                    >
                      <span v-if="playerData.holes === '18' || playerData.holes === F">
                        <template v-if="playerData['score_R' + roundsPlayer] == 'WDN' || playerData['score_R' + roundsPlayer] == 'RTD' || playerData['score_R' + roundsPlayer] == 'DSQ'">
                          
                        </template>
                        <template v-else>
                          <template v-if="
                            sum(
                              (playerData['hole_scores_R' + roundsPlayer] || '')
                                .split(',')
                                .slice(0, 9)
                            ) == ''
                            "
                          >
                          </template>
                          <template v-else>
                            {{
                              sum(
                                (playerData["hole_scores_R" + roundsPlayer] || "")
                                  .split(",")
                                  .slice(0, 9)
                              )
                            }} 
                          </template>
                        </template>
                      </span>
                    </span>
                    <span
                      v-else-if="
                        sum(
                          (playerData['hole_scores_R' + roundsPlayer] || '')
                            .split(',')
                            .slice(0, 9)
                        ) < data.course_out_par
                      "
                      class="up"
                    >
                      <span v-if="playerData.holes === '18' || playerData.holes === F">
                        <template v-if="playerData['score_R' + roundsPlayer] == 'WDN' || playerData['score_R' + roundsPlayer] == 'RTD' || playerData['score_R' + roundsPlayer] == 'DSQ'">
                          
                        </template>
                        <template v-else>
                          <template v-if="
                            sum(
                              (playerData['hole_scores_R' + roundsPlayer] || '')
                                .split(',')
                                .slice(0, 9)
                            ) == ''
                            "
                          >
                          </template>
                          <template v-else>
                            {{
                              sum(
                                (playerData["hole_scores_R" + roundsPlayer] || "")
                                  .split(",")
                                  .slice(0, 9)
                              )
                            }} 
                          </template>
                        </template>
                      </span>
                    </span>
                    <span v-else>
                      <span v-if="playerData.holes === '18' || playerData.holes === F">
                        <template v-if="playerData['score_R' + roundsPlayer] == 'WDN' || playerData['score_R' + roundsPlayer] == 'RTD' || playerData['score_R' + roundsPlayer] == 'DSQ'">
                          
                        </template>
                        <template v-else>
                          <template v-if="
                            sum(
                              (playerData['hole_scores_R' + roundsPlayer] || '')
                                .split(',')
                                .slice(0, 9)
                            ) == ''
                            "
                          >
                          </template>
                          <template v-else>
                            {{
                              sum(
                                (playerData["hole_scores_R" + roundsPlayer] || "")
                                  .split(",")
                                  .slice(0, 9)
                              )
                            }} 
                          </template>
                        </template>
                      </span>
                    </span>
                  </td>
                  <td
                    class="CS"
                    v-for="(r1, index) in (
                      playerData['hole_scores_R' + roundsPlayer] || ''
                    )
                      .split(',')
                      .slice(9, 18)"
                    :key="index + Math.random()"
                    :class="{'twoUnder': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',').slice(9, 18)[index] == -2, 
                    'oneUnder': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',').slice(9, 18)[index] == -1,
                    'threeUnder': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',').slice(9, 18)[index] == -3,
                    'twoOver': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',').slice(9, 18)[index] > 1,
                    'oneOver': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',').slice(9, 18)[index] == 1
                    } "
                  >
                    <span
                      class="twoUnder"
                      v-if="
                        (playerData['hole_vspars_R' + roundsPlayer] || '')
                          .split(',')
                          .slice(9, 18)[index] == -2
                      "
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneUnder"
                      v-else-if="
                        (playerData['hole_vspars_R' + roundsPlayer] || '')
                          .split(',')
                          .slice(9, 18)[index] == -1
                      "
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="threeUnder"
                      v-else-if="
                        (playerData['hole_vspars_R' + roundsPlayer] || '')
                          .split(',')
                          .slice(9, 18)[index] == -3
                      "
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneOver"
                      v-else-if="
                        (playerData['hole_vspars_R' + roundsPlayer] || '')
                          .split(',')
                          .slice(9, 18)[index] == 1
                      "
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="twoOver"
                      v-else-if="
                        (playerData['hole_vspars_R' + roundsPlayer] || '')
                          .split(',')
                          .slice(9, 18)[index] > 1
                      "
                    >
                      {{ r1 }}
                    </span>
                    <span v-else>
                      {{ r1 }}
                    </span>
                  </td>
                  <td class="CS">
                    <span
                      v-if="
                        sum(
                          (playerData['hole_scores_R' + roundsPlayer] || '')
                            .split(',')
                            .slice(9, 18)
                        ) > data.course_in_par
                      "
                      class="down"
                    >
                      <span v-if="playerData.holes === '18' || playerData.holes === F">
                        <template v-if="playerData['score_R' + roundsPlayer] == 'WDN' || playerData['score_R' + roundsPlayer] == 'RTD' || playerData['score_R' + roundsPlayer] == 'DSQ'">
                          
                        </template>
                        <template v-else>
                          <template v-if="
                            sum(
                              (playerData['hole_scores_R' + roundsPlayer] || '')
                                .split(',')
                                .slice(9, 18)
                            ) == ''
                            "
                          >
                          </template>
                          <template v-else>
                          {{
                            sum(
                              (playerData["hole_scores_R" + roundsPlayer] || "")
                                .split(",")
                                .slice(9, 18)
                            )
                          }} 
                          </template>
                        </template>
                      </span>
                    </span>
                    <span
                      v-else-if="
                        sum(
                          (playerData['hole_scores_R' + roundsPlayer] || '')
                            .split(',')
                            .slice(9, 18)
                        ) < data.course_in_par
                      "
                      class="up"
                    >
                      <span v-if="playerData.holes === '18' || playerData.holes === F">
                        <template v-if="playerData['score_R' + roundsPlayer] == 'WDN' || playerData['score_R' + roundsPlayer] == 'RTD' || playerData['score_R' + roundsPlayer] == 'DSQ'">
                          
                        </template>
                        <template v-else>
                          <template v-if="
                            sum(
                              (playerData['hole_scores_R' + roundsPlayer] || '')
                                .split(',')
                                .slice(9, 18)
                            ) == ''
                            "
                          >
                          </template>
                          <template v-else>
                          {{
                            sum(
                              (playerData["hole_scores_R" + roundsPlayer] || "")
                                .split(",")
                                .slice(9, 18)
                            )
                          }} 
                          </template>
                        </template>
                      </span>
                    </span>
                    <span v-else>
                      <span v-if="playerData.holes === '18' || playerData.holes === F">
                        <template v-if="playerData['score_R' + roundsPlayer] == 'WDN' || playerData['score_R' + roundsPlayer] == 'RTD' || playerData['score_R' + roundsPlayer] == 'DSQ'">
                          
                        </template>
                        <template v-else>
                          <template v-if="
                            sum(
                              (playerData['hole_scores_R' + roundsPlayer] || '')
                                .split(',')
                                .slice(9, 18)
                            ) == ''
                            "
                          >
                          </template>
                          <template v-else>
                          {{
                            sum(
                              (playerData["hole_scores_R" + roundsPlayer] || "")
                                .split(",")
                                .slice(9, 18)
                            )
                          }} 
                          </template>
                        </template>
                      </span>
                    </span>
                  </td>
                  <td class="CS">
                    <span
                      v-if="
                        sum(
                          (playerData['hole_scores_R' + roundsPlayer] || '')
                            .split(',')
                            .slice(0, 18)
                        ) > data.course_total_par
                      "
                      class="down"
                    >
                      <span v-if="playerData.holes === '18' || playerData.holes === F">
                        <template v-if="playerData['score_R' + roundsPlayer] == 'WDN' || playerData['score_R' + roundsPlayer] == 'RTD' || playerData['score_R' + roundsPlayer] == 'DSQ'">
                          
                        </template>
                        <template v-else>
                          <template v-if="
                            sum(
                              (playerData['hole_scores_R' + roundsPlayer] || '')
                                .split(',')
                                .slice(0, 18)
                            ) == ''
                            "
                          >
                          </template>
                          <template v-else>
                            {{
                              sum(
                                (playerData["hole_scores_R" + roundsPlayer] || "")
                                  .split(",")
                                  .slice(0, 18)
                              )
                            }}
                          </template>
                        </template>
                      </span>
                    </span>
                    <span
                      v-else-if="
                        sum(
                          (playerData['hole_scores_R' + roundsPlayer] || '')
                            .split(',')
                            .slice(0, 18)
                        ) < data.course_total_par
                      "
                      class="up"
                    >
                      <span v-if="playerData.holes === '18' || playerData.holes === F">
                        <template v-if="playerData['score_R' + roundsPlayer] == 'WDN' || playerData['score_R' + roundsPlayer] == 'RTD' || playerData['score_R' + roundsPlayer] == 'DSQ'">
                          
                        </template>
                        <template v-else>
                          <template v-if="
                            sum(
                              (playerData['hole_scores_R' + roundsPlayer] || '')
                                .split(',')
                                .slice(0, 18)
                            ) == ''
                            "
                          >
                          </template>
                          <template v-else>
                            {{
                              sum(
                                (playerData["hole_scores_R" + roundsPlayer] || "")
                                  .split(",")
                                  .slice(0, 18)
                              )
                            }}
                          </template>
                        </template>
                      </span>
                    </span>
                    <span v-else>
                      <span v-if="playerData.holes === '18' || playerData.holes === F">
                        <template v-if="playerData['score_R' + roundsPlayer] == 'WDN' || playerData['score_R' + roundsPlayer] == 'RTD' || playerData['score_R' + roundsPlayer] == 'DSQ'">
                          
                        </template>
                        <template v-else>
                          <template v-if="
                            sum(
                              (playerData['hole_scores_R' + roundsPlayer] || '')
                                .split(',')
                                .slice(0, 18)
                            ) == ''
                            "
                          >
                          </template>
                          <template v-else>
                            {{
                              sum(
                                (playerData["hole_scores_R" + roundsPlayer] || "")
                                  .split(",")
                                  .slice(0, 18)
                              )
                            }}
                          </template>
                        </template>
                      </span>
                    </span>
                  </td>
                </template>
              </template>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <tbody>
            <tr
              class="RT"
              v-for="(playerData, index) in data.scores.scores_entry"
              :key="index + Math.random()"
            >
              <td v-if="playerData.pos !== 'CUT'">
                <span
                  v-if="
                    index !== 0 &&
                      (playerData.tied !== '=' ||
                        scores[index - 1].pos !== playerData.pos)
                  "
                  class="CP pos"
                >
                  {{ playerData.pos }}
                </span>
                <span v-else-if="index === 0" class="CP pos">
                  {{ playerData.pos }}
                </span>
                <span v-else></span>
              </td>

              <td
                style="text-align:left"
                v-if="playerData.pos !== 'CUT'"
                :title="playerData.member_no"
                :class="{
                  'text-up': playerData.posChang == 'up',
                  'text-down': playerData.posChang == 'down',
                }"
              >
                <template v-if="playerData.profile == 'Y'">
                  <a 
                    class="linkcolor"
                    :href="
                      '/playerprofile/' + playerData.id
                    "
                  >
                    {{ playerData.initials }}{{ playerData.surname }}</a
                  > 
                </template>
                <template v-else>
                    {{ playerData.initials }}{{ playerData.surname }}
                </template>
                <span style="margin-left: 5px;" v-if="playerData.pro_ind === 'Am'">(a)</span>
                <span v-if="data.multi_course === 'Y'">
                  <span :class="playerData['course_colour_R' + data.pft_round]">
                    •
                  </span>
                </span>
                <span
                  v-if="showGender === 'Y'"
                  class="multiDot-size-reportgender"
                  :class="{female : playerData.gender === 'F', male : playerData.gender === 'M'}"
                  >•</span>
              </td>
              <td
                v-if="playerData.pos !== 'CUT'"
                class="CF"
                :title="playerData.nationality"
              >
                <span>
                  <template v-if="represents_flag_type === 'N'">
                    {{playerData.nationality}}
                  </template>
                  <template v-else>
                    <img
                      class="flag"
                      :src="
                        (config.VUE_APP_FLAG_URL + playerData.nationality)
                          | lowercase
                      "
                    />
                  </template>
                </span>
              </td>
              <template v-if="data.score_type === 'P' || data.tt_type === 'P' && data.stableford === 'N'">
                <td v-if="playerData.pos !== 'CUT'" class="CT">
                  <span v-if="playerData.vspar < 0" :class="up">
                    {{ playerData.vspar }}
                  </span>
                  <span v-else-if="playerData.vspar > 0" :class="down">
                    {{ playerData.vspar }}
                  </span>
                  <span v-else>
                    {{ playerData.vspar }}
                  </span>
                </td>
                <template v-if="title.includes('Scoreboard')">
                  <td v-if="playerData.pos !== 'CUT'">
                    <span v-if="playerData['vspar_R' + roundsPlayer] < 0" :class="up">
                      {{ playerData["vspar_R" + roundsPlayer] }}
                    </span>
                    <span
                      v-else-if="playerData['vspar_R' + roundsPlayer] > 0"
                      :class="down"
                    >
                      {{ playerData["vspar_R" + roundsPlayer] }}
                    </span>
                    <span v-else>
                      {{ playerData["vspar_R" + roundsPlayer] }}
                    </span>
                  </td>                  
                </template>

              </template>
              <template v-if="data.score_type === 'P' || data.tt_type === 'P' && data.stableford === 'Y'">
                <td :class="{minus : playerData.score < 0, plus : playerData.score > 1, zero : playerData.score === '0'}">{{playerData.score}}</td>
              </template>
              <td class="cut" colspan="30" v-if="playerData.pos === 'CUT'">
                {{ playerData.cut_round }}
              </td>
              <template v-if="playerData.pos !== 'CUT'">
                <td
                  class="CS"
                  v-for="(r1, index) in (
                    playerData['hole_points_R' + roundsPlayer] || ''
                  )
                    .split(',')
                    .slice(0, 9)"
                  :key="index + Math.random()"
                  :class="{'twoUnder': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',')[index] == -2, 
                  'oneUnder': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',')[index] == -1,
                  'threeUnder': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',')[index] == -3,
                  'twoOver': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',')[index] > 1,
                  'oneOver': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',')[index] == 1,
                  } 
                  "
                >
                  <span
                    class="twoUnder"
                    v-if="
                      (playerData['hole_vspars_R' + roundsPlayer] || '').split(
                        ','
                      )[index] == -2
                    "
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneUnder"
                    v-else-if="
                      (playerData['hole_vspars_R' + roundsPlayer] || '').split(
                        ','
                      )[index] == -1
                    "
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="threeUnder"
                    v-else-if="
                      (playerData['hole_vspars_R' + roundsPlayer] || '').split(
                        ','
                      )[index] == -3
                    "
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneOver"
                    v-else-if="
                      (playerData['hole_vspars_R' + roundsPlayer] || '').split(
                        ','
                      )[index] == 1
                    "
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="twoOver"
                    v-else-if="
                      (playerData['hole_vspars_R' + roundsPlayer] || '').split(
                        ','
                      )[index] > 1
                    "
                  >
                    {{ r1 }}
                  </span>
                  <span v-else>
                    {{ r1 }}
                  </span>
                </td>
                <td class="CS">
                  <span
                    v-if="
                      sum(
                        (playerData['hole_points_R' + roundsPlayer] || '')
                          .split(',')
                          .slice(0, 9)
                      ) > data.course_out_par
                    "
                    :class="sumclass(
                        (playerData['hole_points_R' + roundsPlayer] || '')
                          .split(',')
                          .slice(0, 9)
                      )"
                  >
                    <span v-if="playerData.holes === '18' || playerData.holes === F">
                      {{
                        sum(
                          (playerData["hole_points_R" + roundsPlayer] || "")
                            .split(",")
                            .slice(0, 9)
                        )
                      }} 
                    </span>
                  </span>
                  <span
                    v-else-if="
                      sum(
                        (playerData['hole_points_R' + roundsPlayer] || '')
                          .split(',')
                          .slice(0, 9)
                      ) < data.course_out_par
                    "
                    :class="sumclass(
                      (playerData['hole_points_R' + roundsPlayer] || '')
                        .split(',')
                        .slice(0, 9)
                    )"
                  >
                    <span v-if="playerData.holes === '18' || playerData.holes === F" >
                      {{
                        sum(
                          (playerData["hole_points_R" + roundsPlayer] || "")
                            .split(",")
                            .slice(0, 9)
                        )
                      }}
                    </span>
                  </span>
                  <span v-else>
                    <span v-if="playerData.holes === '18' || playerData.holes === F">
                      {{
                        sum(
                          (playerData["hole_points_R" + roundsPlayer] || "")
                            .split(",")
                            .slice(0, 9)
                        )
                      }}
                    </span>
                  </span>
                </td>
                <td
                  class="CS"
                  v-for="(r1, index) in (
                    playerData['hole_points_R' + roundsPlayer] || ''
                  )
                    .split(',')
                    .slice(9, 18)"
                  :key="index + Math.random()"
                  :class="{'twoUnder': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',').slice(9, 18)[index] == -2, 
                  'oneUnder': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',').slice(9, 18)[index] == -1,
                  'threeUnder': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',').slice(9, 18)[index] == -3,
                  'twoOver': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',').slice(9, 18)[index] > 1,
                  'oneOver': (playerData['hole_vspars_R' + roundsPlayer] || '').split(',').slice(9, 18)[index] == 1
                  } "
                >
                  <span
                    class="twoUnder"
                    v-if="
                      (playerData['hole_vspars_R' + roundsPlayer] || '')
                        .split(',')
                        .slice(9, 18)[index] == -2
                    "
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneUnder"
                    v-else-if="
                      (playerData['hole_vspars_R' + roundsPlayer] || '')
                        .split(',')
                        .slice(9, 18)[index] == -1
                    "
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="threeUnder"
                    v-else-if="
                      (playerData['hole_vspars_R' + roundsPlayer] || '')
                        .split(',')
                        .slice(9, 18)[index] == -3
                    "
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="oneOver"
                    v-else-if="
                      (playerData['hole_vspars_R' + roundsPlayer] || '')
                        .split(',')
                        .slice(9, 18)[index] == 1
                    "
                  >
                    {{ r1 }}
                  </span>
                  <span
                    class="twoOver"
                    v-else-if="
                      (playerData['hole_vspars_R' + roundsPlayer] || '')
                        .split(',')
                        .slice(9, 18)[index] > 1
                    "
                  >
                    {{ r1 }}
                  </span>
                  <span v-else>
                    {{ r1 }}
                  </span>
                </td>
                <td class="CS">
                  <span
                    v-if="
                      sum(
                        (playerData['hole_points_R' + roundsPlayer] || '')
                          .split(',')
                          .slice(9, 18)
                      ) > data.course_in_par
                    "
                    :class="sumclass(
                      (playerData['hole_points_R' + roundsPlayer] || '')
                        .split(',')
                        .slice(9, 18)
                    )"
                  >
                    <span v-if="playerData.holes === '18' || playerData.holes === F">
                      {{
                        sum(
                          (playerData["hole_points_R" + roundsPlayer] || "")
                            .split(",")
                            .slice(9, 18)
                        )
                      }}
                    </span>
                  </span>
                  <span
                    v-else-if="
                      sum(
                        (playerData['hole_points_R' + roundsPlayer] || '')
                          .split(',')
                          .slice(9, 18)
                      ) < data.course_in_par
                    "
                    :class="sumclass(
                      (playerData['hole_points_R' + roundsPlayer] || '')
                        .split(',')
                        .slice(9, 18)
                    )"
                  >
                    <span v-if="playerData.holes === '18' || playerData.holes === F">
                      {{
                        sum(
                          (playerData["hole_points_R" + roundsPlayer] || "")
                            .split(",")
                            .slice(9, 18)
                        )
                      }}
                    </span>
                  </span>
                  <span v-else>
                    <span v-if="playerData.holes === '18' || playerData.holes === F">
                      {{
                        sum(
                          (playerData["hole_points_R" + roundsPlayer] || "")
                            .split(",")
                            .slice(9, 18)
                        )
                      }}
                    </span>
                  </span>
                </td>
                <td class="CS">
                  <span
                    v-if="
                      sum(
                        (playerData['hole_points_R' + roundsPlayer] || '')
                          .split(',')
                          .slice(0, 18)
                      ) > data.course_total_par
                    "
                    :class="{minus : playerData.score.includes('-'), plus : playerData.score > 1, zero : playerData.score === '0'}"
                  >
                    <span v-if="playerData.holes === '18' || playerData.holes === F">
                      {{
                        sum(
                          (playerData["hole_points_R" + roundsPlayer] || "")
                            .split(",")
                            .slice(0, 18)
                        )
                      }}
                    </span>
                  </span>
                  <span
                    v-else-if="
                      sum(
                        (playerData['hole_points_R' + roundsPlayer] || '')
                          .split(',')
                          .slice(0, 18)
                      ) < data.course_total_par
                    "
                    :class="{minus : playerData.score.includes('-'), plus : playerData.score > 1, zero : playerData.score === '0'}"
                  >
                    <span v-if="playerData.holes === '18' || playerData.holes === F">
                      {{
                        sum(
                          (playerData["hole_points_R" + roundsPlayer] || "")
                            .split(",")
                            .slice(0, 18)
                        )
                      }}
                    </span>
                  </span>
                  <span v-else>
                    <span v-if="playerData.holes === '18' || playerData.holes === F">
                      {{
                        sum(
                          (playerData["hole_points_R" + roundsPlayer] || "")
                            .split(",")
                            .slice(0, 18)
                        )
                      }}
                    </span>
                  </span>
                </td>
              </template>
            </tr>
          </tbody>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "hbh",
  props: ["tabs", "data", "scores", "previousData", "develop", "config", 'showGender', 'represents_flag_type', 'title'],
  data() {
    return {
      up: "up",
      down: "down",
      CS: "CS",
      CV: "CV",
      CT: "CT",
      CN: "CN",
      CP: "CP",
      CF: "CF",
    };
  },
  computed: {
    roundsPlayer: function() {
      return parseInt(this.data.pft_round);
    },
  },
  watch: {
    scores: {
      immediate: true,
      handler(newValue, oldValue) {
        newValue.forEach((value1, key1) => {
          oldValue.forEach((value2, key2) => {
            if (value1.id === value2.id) {
              if (key1 < key2) {
                value1.posChang = "up";
              } else if (key1 > key2) {
                value1.posChang = "down";
              }
            }
          });
        });

        console.log(newValue);
      },
      deep: true,
    },
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    },
  },
  methods: {
    sum: function(nums) {
      let result = 0;
      nums.forEach(function(n) {
        result += n * 1;
      });
      if (result == 0)
          return result;
      else if (result > 0)
          return result;
      else if (result < 0)
        return result;

      return result;
    },
    sumclass: function(nums) {
      let result = 0;
      nums.forEach(function(n) {
        result += n * 1;
      });
      if (result == 0)
          return 'minus';
      else if (result > 0)
          return 'plus';
      else if (result < 0)
        return 'zero';

      return result;
    },
  },
};
</script>

<style scoped>
[v-cloak] { 
  display:none; 
}
::v-deep a.linkcolor {
  color: #337ab7!important
}
table.tableMob {
  overflow: hidden;
}
.minus {
  color: #1e90ff;
}
.plus {
  color: #a52a2a
}
.zero {
  color: #000;
}
.female {
  color: #e33;
}
.male {
  color: #037
}
span.multiDot-size-reportgender  {
  font-size: 30px!important;
  line-height: 0px !important;
  position: relative;
  top: 6px;
}
span.multiDot-size-report {
  float: right;
  font-size: 60px!important;
  line-height: 0px !important;
  position: relative;
  top: 12px;
}
#hbh {
  padding-bottom: 2%;
}

::v-deep th {
  padding: 5px;
  font-size: 14px;
}
::v-deep td {
  /* padding: 8px; */
  padding-top: 14px !important;
  /* padding-right: 6px !important; */
  padding-bottom: 14px !important;
  font-size: 14px !important;
  /* padding-left: 8px !important; */
  font-weight: 400;
}
.B {
  color: #333333;
  font-size: 60px !important;
  line-height: 25px !important;
  vertical-align: bottom;
  float: right;
}
.Y {
  color: #d8db22;
  font-size: 60px !important;
  line-height: 25px !important;
  vertical-align: bottom;
  float: right;
}
.C {
  color: #1e90ff;
  font-size: 60px !important;
  line-height: 25px !important;
  vertical-align: bottom;
  float: right;
}
.R {
  color: #bb0000;
  font-size: 60px !important;
  line-height: 25px !important;
  vertical-align: bottom;
  float: right;
}
.G {
  color: #3c9933;
  font-size: 60px !important;
  line-height: 25px !important;
  vertical-align: bottom;
  float: right;
}
.O {
  color: #e07020;
  font-size: 60px !important;
  line-height: 25px !important;
  vertical-align: bottom;
  float: right;
}

.Brow {
  background-color: #333333 !important;
  color: white;
  font-size: 16px;
}
.Rrow {
  background-color: #bb0000 !important;
  color: white;
  font-size: 16px;
}
.Orow {
  background-color: #e07020 !important;
  color: white;
  font-size: 16px;
}
.Grow {
  background-color: #3c9933 !important;
  color: white;
  font-size: 16px;
}
.Yrow {
  background-color: #d8db22 !important;
  color: white;
  font-size: 16px;
}
.Crow {
  background-color: #1e90ff !important;
  color: white;
  font-size: 16px;
}
tr:nth-child(even) {
  background-color: #F5F5F5;
}
tr:nth-child(odd) {
  background-color: white;
}
.sponsor {
  margin-top: -10%;
  width: 20%;
  padding-left: 10%;
}
.title {
  text-align: center;
  background-color: #133f7b !important;
  color: white;
}
.developGreen {
  background-color: #76c3bd !important;
}
.developGreen > th {
  background-color: #76c3bd !important;
}
.pos {
  color: black;
  padding: 5px 15px 5px 15px;
}
.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
td.oneUnder > span {
  color: #fff !important;
  background-color: #a52a2a;
  padding: 10px;
}

td.twoUnder > span {
  color: #fff !important;
  background-color: #3c9933;
  padding: 10px;
}
td.threeUnder > span {
  color: #fff !important;
  background-color: #fbbc04;
  padding: 10px;
}

td.oneOver > span {
  color: #fff !important;
  background-color: #1e90ff;
  padding: 10px;
}

td.twoOver > span {
  color: #fff !important;
  background-color: #000;
  padding: 10px;
}
.twoOver > span {
  color: #fff!important;
}

.RT {
  /* border: 1px solid black; */
  text-align: center;
  font-size: 17px;
}
.CP {
  width: 2%;
  text-align: center;
}
.CN {
  width: 20%;
}
.CF {
  width: 5%;
  text-align: center;
}
.CT {
  width: 3%;
  /* border: 1px solid black; */
  text-align: center;
  font-size: 17px;
}
.CV {
  width: 3%;
  /* border: 1px solid black; */
  text-align: center;
  font-size: 17px;
}
.CS {
  /* border: 1px solid black; */
  text-align: center;
  width: 3%;
  font-size: 17px;
}
td.CS {
  color: black;
}
td > span {
  color: black;
}
.cut {
  background-color: red;
  color: white;
}
@media only screen and (max-width: 480px) {
  .tableMob {
    width: 275%;
    overflow: scroll;
  }
  .blue {
    background-color: #143f7b!important;
  }
}
</style>
