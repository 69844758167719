<template>
  <div>
    <ReportTableTopSection
      :develop="develop"
      :config="config"
      :season="tmparams.tm_params.season_code"
      :code="tmparams.code"
      :Stableford="Stableford"
      :showGender="showGender"
      @onEmitEvent="handleEmitEvent($event)"
      @onEmitEventReport="handleEmitEventReport($event)"
      @onEmitEventReportData="handleEmitEventReportData($event)"
    />
    <b-container style="margin-top:30px">
      <b-tab title="Leaderboard" active>
        <ReportTable
          :data="data"
          :previousData="previousData"
          :home="home"
          :title="title"
          :develop="develop"
          :season="tmparams.tm_params.season_code"
          :code="tmparams.code"
          :config="config"
          :match="tmparams.match_play"
          :team="teamEvent"
          :showGender="showGender"
          :Stableford="Stableford"
        />
      </b-tab>
      <b-tab title="Hole by Hole">
        <!-- <HBH
          :tabs="tabs"
          :data="data"
          :scores="data.scores.scores_entry"
          :previousData="previousData"
          :develop="develop"
          :config='config'
          :showGender="showGender"
          :Stableford="Stableford"
        /> -->
        <HBH :data="data" :previousData="previousData" :config="config" />
      </b-tab>
    </b-container>
  </div>
</template>

<script>
import ReportTableTopSection from "@/components/Report-Top-Section.vue";
import HBH from "@/ocs/reports/scores/hole-by-hole/components/hbh.vue";
import ReportTable from "@/components/reportTable.vue";
import axios from "axios";
export default {
  name: "Leaderboard",
  props: ["develop", "config", "showGender", "Stableford"],
  components: {
    ReportTableTopSection,
    HBH,
    ReportTable
  },
  data() {
    return {
      data: [],
      previousData: [],
      tmparams: [],
      tourinfo: [],
      report: "",
      drop: "drop",
      home: false,
      tabs: true,
      title: this.title,
      seasonCode: this.seasonCode,
      TournCode: this.TournCode
    };
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_TIC_BASE +
          "apga" +
          "/tmticx?LEADERBOARD=" +
          new Date().getTime()
      )
      .then(response => {
        this.data = response.data;
        this.seasonCode = response.data.tm_params.season_code;
        this.TournCode = response.data.code;
        // this.classGroup = response.data.class_group.toLowerCase().trim();
        this.classGroup = response.data.class_group;
        if (this.classGroup === "QSC") {
          return axios.get(
            process.env.VUE_APP_TIC_BASE +
              "qsc" +
              "/" +
              this.seasonCode +
              "/" +
              this.seasonCode +
              "-" +
              this.TournCode +
              "-" +
              "tmticx?QSC-TOURNINFO=" +
              new Date().getTime()
          );
        } else {
          return axios.get(
            process.env.VUE_APP_TIC_BASE +
              this.develop +
              "/" +
              this.seasonCode +
              "/" +
              this.seasonCode +
              "-" +
              this.TournCode +
              "-" +
              "tmticx?TOURNINFO=" +
              new Date().getTime()
          );
        }
      })
      .then(response => {
        console.log("IN");
        this.tmparams = response.data;
        console.log("this.tmparams");
        console.log(this.tmparams);
        this.showGender = response.data.show_gender;
        this.Stableford = response.data.stableford;
        this.livemessage = response.data.live_message;
        this.livemessageText = response.data.live_scoring_mess;
        this.playSuspended = response.data.play_suspended;
        this.tourinfo = response.data;
        this.reports = response.data.reports.reports_entry;
        this.reportData = response.data.reports;
        this.until = response.data.days_away;
        console.log("OUT");
        // this.ReportURL();
        let rep_url = this.reportTitle + "&REPORT=" + new Date().getTime();
        return axios.get(rep_url);
      })
      .then(response => {
        this.data = response.data;
      });
  },
  methods: {
    handleEmitEvent(title) {
      this.title = title;
      console.log("this.title");
      console.log(this.title);
    },
    handleEmitEventReport(report) {
      this.report = report;
      console.log("this.report");
      console.log(this.report);
    },
    handleEmitEventReportData(data) {
      this.data = data;
      console.log("this.data");
      console.log(this.data);
    },
    handleEmitEventSeason(seasonCode) {
      this.seasonCode = seasonCode;
      console.log("this.seasonCode");
      console.log(this.seasonCode);
    },
    handleEmitEventTourn(TournCode) {
      this.TournCode = TournCode;
      console.log("this.TournCode");
      console.log(this.TournCode);
    }
  },
  computed: {
    currentReport: function(cr) {
      console.log("TEST");
      if (this.report === "") {
        if (Array.isArray(this.tmparams.reports.reports_entry)) {
          cr = this.tmparams.reports.reports_entry[
            this.tmparams.reports.reports_entry.length - 4
          ].report_url;
          console.log("cr == ");
          console.log(cr);
        } else cr = this.tmparams.reports.reports_entry.report_url;
      } else cr = this.report;
      return cr;
    }
  },
  created() {
    setInterval(this.updateStuff, 30000);
  }
};
</script>

<style scoped>
.colorMess {
  background: red !important;
  color: white;
  font-weight: 500;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.livemessage {
  background: #133f7b;
  color: white;
  font-weight: 500;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.reports {
  padding-top: 4px;
}
span.reportsPad {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  display: contents;
}
::v-deep .leaderboardTabs > div > ul {
  display: none;
}
.reportTitle {
  margin-top: 1.5em;
}
::v-deep .nav-tabs {
  border-bottom: 0;
  /* background-color: #d4d4d4; */
}
::v-deep .nav-tabs > li > a {
  color: #133f7b !important;
}
.cor-grn {
  color: #3c9933 !important;
  float: right;
  margin-top: 13px;
  font-size: 60px;
}
::v-deep .tab-pane > div > table > thead > tr > th:first-child {
  border-top-left-radius: 0;
}
::v-deep .tab-pane > div > table > thead > tr > th:last-child {
  border-top-right-radius: 0;
}
.cor-org {
  color: #e07020 !important;
  float: right;
  margin-top: 13px;
  font-size: 60px;
}
.cut-row {
  background: #aa0003 !important;
  color: #fff;
  text-align: center;
}
.iframemovement {
  float: right;
  margin-right: -3px;
}
.bannerlogosReport {
  float: right;
}
::v-deep .dropdownReport > ul {
  width: 300px;
  text-align: center;
}
::v-deep .btn-secondary {
  color: #000;
  background-color: #ffffff;
  border-color: #ebebeb;
  font-size: 11pt;
  width: 250px;
}
.ReportFilter {
  display: flex;
}
.filterReports {
  color: #16498f;
  font-size: 10pt;
  font-weight: bold;
}
.main {
  padding-top: 2%;
  padding-bottom: 2%;
}
.drop {
  padding-left: 10%;
  padding-bottom: 2%;
}
.developGreen {
  color: #76c3bd !important;
}
::v-deep .developGreen > div > ul > li > .nav-link.active {
  background-color: #76c3bd !important;
  border-color: #76c3bd !important;
}
.sponsor {
  margin-top: -10%;
  width: 20%;
  padding-left: 10%;
}
.multi {
  font-size: 13px !important;
  line-height: 50px;
  color: #0b3f7e;
  font-weight: bold;
  margin-top: 15px;
}
.B {
  color: #333333;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
}
.Y {
  color: #d8db22;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
}
.C {
  color: #1e90ff;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
}
.R {
  color: #bb0000;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
}
.G {
  color: #3c9933;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
}
.O {
  color: #e07020;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
}

::v-deep .nav-link.active {
  color: white !important;
  background-color: #133f7b !important;
  width: 100%;
  border-color: #133f7b !important;
  height: 100%;
}
.selectRD {
  padding: 20px 1%;
}
::v-deep .nav-link {
  color: black !important;
  width: 100%;
  font-size: 14px;
}
.dropSel {
  padding: 0.5% 1%;
  background-color: #fff;
  border-radius: 10px;
  height: 100px;
}
::v-deep li.nav-item {
  width: 25%;
  text-align: center;
}
::v-deep .mt-3 {
  margin-top: 0 !important;
}
.tourLogoS {
  max-width: 180px;
  margin: 0;
  height: auto;
  margin-bottom: 56px;
  display: inline-block;
  max-height: 150px;
}
.tournHead {
  text-align: left;
  color: #133f7a;
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 19px !important;
}
.dates {
  font-size: 13px !important;
}
.top {
  background-color: #ebebeb;
}
.mid {
  background-color: #f1f1f1;
}
@media only screen and (max-width: 1200px) {
  span.reportPad {
    width: 510px !important;
    padding-left: 15px;
    padding-right: 15px;
    display: block !important;
  }
  .bannerlogosReport {
    display: none;
  }
  .iframemovement {
    float: right;
    margin-right: -3px;
    display: none;
  }
}
@media only screen and (max-width: 990px) {
  span.reportsPad {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    display: block !important;
  }
  .tourLogoS {
    margin-bottom: 0;
    padding-top: 50px;
  }
  .drop {
    padding-left: 35%;
  }
  .mobC {
    text-align: center;
  }
}
@media only screen and (max-width: 768px) {
  .mobHide {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .ReportFilter {
    display: block;
    text-align: center;
    position: relative;
    top: 8px;
  }
  .sponsor {
    margin-top: 2%;
    width: 35%;
    padding-left: 4%;
  }
  .drop {
    /* margin-top: -10%; */
    margin-top: 0;
    padding: 0;
  }
  .selectRD {
    padding: 2% 4%;
  }
  .reportDropdown {
    margin: auto;
    display: block;
  }
  .dropdownReport {
    display: contents;
  }
  ::v-deep .nav-link {
    font-size: 12pt;
  }
}
</style>
